header {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 1rem 0;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1000;
  animation: slideDown 0.5s ease-in-out;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  width: 3000px;
}
.image{
  margin-left: 60px;
  margin-top: -19px;
  margin-bottom: 10px;
}

header h1 {
  margin: 0;
}

.user-role{
  font-size: 25px;
  font-weight: 600;
  color:#0e5210;
  margin-right: -100px;
  text-align: center;
  margin-top: -55px;
}

nav {
  background: linear-gradient(to right, #006400, #00FF00);
  height: 50px;
  padding: 0 20px; /* Adjusted padding for horizontal alignment */
  display: flex;
  font-weight: bolder;
  align-items: center;
  margin: 1rem 0;
  gap: 1rem; /* Adjust space between each item */
  width: 3000px;
  margin-top: 30px;
  margin-left: -10px;
}

nav a, .dropdown {
  color: rgb(253, 253, 253);
  text-decoration: none;
  transition: color 0.3s;
  margin-left: 40px;
  font-size: 20px;
}

nav a:hover, .dropdown:hover .dropbtn {
  color: #edb834;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  color: rgb(255, 255, 255);
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  background: none;
  font-weight: bolder;
  transition: color 0.3s;
}

/* Ensure dropdown-content is properly positioned */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  text-align: left;
  width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Show dropdown when hovering */
.dropdown:hover .dropdown-content,
.dropdown-content.show {
  display: block;
}

/* Fix for dropdown items disappearing */
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

/* Fix positioning issue */
.dropdown-content {
  left: 0;
  top: 100%;
  min-width: 180px;
}

/* Adjust z-index to keep dropdown above other elements */
.dropdown-content {
  z-index: 1001;
}


/* Animations */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
} 

.login button,
.login a {
  background: none;
  border: none;
  color:white;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  margin-left: 45px;
}

.login button:hover,
.login a:hover {
background:none;
}

.login .fa-user {
  margin-right: 20px;
  margin-left: 5px;
  margin-top: -9px;
}

.password-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 2px solid black;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.password-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-modal input {
  margin: 10px 0;
  padding: 5px;
  border: 1px solid black;
}

.password-modal button {
  margin: 5px;
  padding: 8px 12px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
}

.password-modal button:hover {
  background-color: #0056b3;
}
